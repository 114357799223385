<template>
    <section>
        <div class="custom-scroll overflow-auto" :style="`height:calc(100vh - ${datos.pago_estado == 2 && botones ? 246 : 178}px);`">
            <div class="row mx-0 justify-center py-3">
                <div class="col-10 d-middle br-8 py-1 " :class="boxClass(datos)">
                    <i class="icon-cash text-general f-22 pr-2" :class="boxIcon(datos)" />
                    <p class="col pl-2 text-general f-17 f-500">
                        <template v-if="datos && datos.pago_estado == 1">
                            Este Pedido no tiene asignado un crédito
                        </template>
                        <template v-else>
                            Crédito {{ datos.pago_estado == 2 ? 'pendiente' : (datos.pago_estado == 3) ? 'pagado' : 'anulado' }}
                        </template>
                    </p>
                </div>
            </div>
            <div class="row mx-0 px-4">
                <div class="col text-general tres-puntos">
                    <p class="f-14">Total:</p>
                    <p class="f-15 f-600 tres-puntos">{{ separadorNumero(datos.total) }}</p>
                </div>
                <div class="col text-general tres-puntos">
                    <p class="f-14">Pagué:</p>
                    <p class="f-15 f-600 tres-puntos">{{ separadorNumero(datos.pago) }}</p>
                </div>
                <div class="col text-general tres-puntos">
                    <p class="f-14">Debo:</p>
                    <p class="f-15 f-600 text-general-red tres-puntos">{{ separadorNumero(datos.debe) }}</p>
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div class="row mx-0 bg-light-f5 py-2">
                <div class="col-auto text-general2">
                    Cuotas establecidas
                </div>
                <div class="ml-auto mr-3 text-general2">
                    {{ datos.cuotas.length }}
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div class="row mx-0 justify-center">
                <div v-for="(cuota, c) in datos.cuotas" :key="c" class="col-10 px-0 py-2 border br-8 mb-3">
                    <div class="row mx-0">
                        <p class="col text-general f-16 f-600">{{ separadorNumero(cuota.valor) }}</p>
                        <p class="col pl-0 text-right text-general f-15">
                            <span v-text="textosCuotas(cuota)" />
                            <i :class="`f-18 ${iconoCuotas(cuota)}`" />
                        </p>
                        <div class="col-12 text-general f-15 d-flex">
                            {{ formatearFecha(cuota.fecha) }}
                            <span v-if="isParcial(cuota)" class="text-right ml-auto"> {{ separadorNumero(cuota.valor_pendiente) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div class="row mx-0 bg-light-f5 py-2">
                <div class="col-auto text-general2">
                    Historial de pagos
                </div>
                <div class="ml-auto mr-3 text-general2">
                    {{ datos.historial.length }}
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div class="row mx-0 justify-center">
                <div v-for="(historial, h) in datos.historial" :key="h" class="col-10 px-0 py-2 border br-8 mb-3 cr-pointer" @click="detallePago(historial)">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <img :src="imagenHistorial(historial)" alt="" />
                        </div>
                        <div class="col px-2 pt-1">
                            <div v-if="historial.tipo_efectivo != 4" class="row mx-0">
                                <p class="col text-general tres-puntos f-16 f-500">
                                    {{ separadorNumero(historial.valor) }}
                                </p>
                                <div v-if="historial.x_payus" class="col d-flex justify-content-end">
                                    <div :class="`px-3 br-20 ${historial.estado == 2 || historial.estado == 0 ? 'aprobado' : historial.estado == 1 ? 'pendiente' : 'cancelado'}`">
                                        {{ historial.estado == 2 || historial.estado == 0 ? 'Aprobado' : historial.estado == 1 ? 'Pendiente' : 'Cancelado' }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 my-2">
                                <p class="col text-general2 f-15">
                                    {{ nombreHistorial(historial) }}
                                </p>
                                <p v-if="historial.ref" class="col text-right tres-puntos text-general">
                                    Ref: {{ historial.x_payus && historial.x_payus.transaction_id || historial.id }}
                                </p>
                            </div>
                            <div class="row mx-0">
                                <p class="col-12 text-general2 f-15">
                                    {{ formatearFecha(historial.created_at) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Registrar pago boton -->
        <div v-if="datos.pago_estado == 2" class="border py-3" style="border-radius:12px 12px 0px 0px;">
            <div class="row mx-0 justify-center">
                <template v-if="vista == 1">
                    <div v-if="[1, 2].includes(pedidos.pago_estado)" class="bg-general px-2 d-middle-center text-white br-8 cr-pointer" style="height:32px;" @click="modalPronto()">
                        Pagar en línea
                    </div>
                    <div v-if="pedido.estado >= 2 && (pedido.estado < 20 && pedido.estado > 29)" class="text-general ml-2 border-black px-2 d-middle-center text-general br-8 cr-pointer" style="height:32px;" @click="registrarTransferencia()">
                        Registrar transferencia
                    </div>
                </template>
                <div v-if="[2, 3, 4, 5].includes(vista)" class="bg-general px-2 d-middle-center text-white br-8 cr-pointer" style="height:32px;" @click="pagarPedido()">
                    {{ vista === 2 || vista === 3 || vista === 4 || vista === 5? 'Registrar Pago' : 'Sin definir' }}
                </div>
                <div v-if="(vista === 3 || vista === 5) || (vista === 4 && datos.historial.length)" class="text-general ml-2 border-black px-2 d-middle-center text-general br-8 cr-pointer" style="height:32px;" @click="anularCredito()">
                    Anular crédito
                </div>
                <div v-if="vista === 2" class="text-general ml-2 border-black px-2 d-middle-center text-general br-8 cr-pointer" style="height:32px;" @click="$refs.modalCancelarCredito.toggle()">
                    Cancelar Crédito
                </div>
                <div v-if="vista === 4 && datos.historial.length === 0" class="text-general ml-2 border-black px-2 d-middle-center text-general br-8 cr-pointer" style="height:32px;" @click="$refs.modalCancelarCredito.toggle()">
                    Cancelar Crédito
                </div>
            </div>
        </div>

        <modal ref="modalPagarPedidoTendero" titulo="Pagar Pedido" no-aceptar adicional="Pagar" @adicional="handlePago()">
            <div class="row mx-0 justify-center">
                <div class="col-8">
                    <p class="text-general f-14 pl-3">Valor a pagar</p>
                    <input-miles v-model="valorPagar" class="w-100" :money-options="money" />
                </div>
                <div class="col-8 text-general f-15 text-center mt-4">
                    A continuación la aplicación te llevará a una pagina segura de pago en línea.
                </div>
            </div>
            <pagos-boton-payu ref="botonPayu" />
        </modal>

        <!-- Modal para registrar pago sin PAYUS -->
        <modal ref="modalRegistrarPago" v-loading="loading" titulo="Registrar Pago" :tamano="(vista != 2 && vista != 3) ? 'modal-xl' : 'modal-md'" no-aceptar adicional="Pagar" @adicional="handlePago()">
            <template v-if="vista != 2 && vista != 3">
                <div class="row mx-0 justify-center">
                    <div class="col-6 text-general f-15 mt-4">
                        <p class="text-general f-14 pl-3">Seleccionar forma de pago</p>
                        <el-select v-model="tipoPago" class="w-100" @change="buscarCaja()">
                            <el-option
                            v-for="data in formasPago"
                            :key="data.id"
                            :value="data.id"
                            :label="data.tipo"
                            />
                        </el-select>
                    </div>
                    <div class="col text-general f-15 mt-4">
                        <template v-if="tipoPago != 3">
                            <p class="text-general f-14 pl-3">Caja / Cuenta bancaria</p>
                            <el-select v-model="caja" class="w-100">
                                <el-option
                                v-for="data in cajas"
                                :key="data.id"
                                :value="data.id"
                                :label="data.nombre"
                                />
                            </el-select>
                        </template>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-6">
                        <div class="mt-2">
                            <p class="text-general f-14 pl-3">Valor a pagar</p>
                            <input-miles v-model="valorPagar" class="w-100" :money-options="money" />
                        </div>
                        <div class="mt-2">
                            <p class="text-general f-14 pl-3">Comentario</p>
                            <el-input v-model="comentarioPago" type="textarea" maxlength="300" rows="4" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="mt-2">
                            <slim-cropper v-show="tipoPago == 2" ref="fotoTransferencia" :options="slimOptions" class="border cr-pointer" style="height:300px;width:300px;background-color:#F5F5F5;border-radius:6px;" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row mx-0 justify-center">
                    <div class="col-10">
                        <p class="text-general f-14 pl-3">Valor a pagar</p>
                        <input-miles v-model="valorPagar" class="w-100" :money-options="money" />
                    </div>
                    <div class="col-10 text-general f-15 mt-4">
                        <p class="text-general f-14 pl-3">Comentario</p>
                        <el-input v-model="comentarioPago" type="textarea" maxlength="300" rows="3" />
                    </div>
                </div>
            </template>
        </modal>
        <modalSiNo
        ref="modalCancelarCredito"
        titulo="Cancelar Credito"
        mensaje="¿Desea deshacer los datos del crédito?"
        icon="close"
        @accion="accionCancelar"
        />
        <modal ref="modalPronto" titulo="¡Espera!" no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <div class="col-8">
                    <p class="text-general f-14 pl-3">Próximamente podrás pagar tus pedidos en linea, en el momento registras tus transferencias.</p>
                </div>
            </div>
        </modal>
        <modal-anular-credito ref="modalAnularCreditoCliente" @actualizar="pedido.pago_estado = 4" />
        <modal-anular-credito-tendero ref="modalAnularCreditoTendero" @actualizar="pedido.pago_estado = 4" />
        <modal-detalle-pago ref="modalDetallePago" />
        <modal-detalle-pago-transferencia ref="modalDetallePagoTransferencia" />
        <modal-registrar-transferencia ref="modalRegistrarTransferencia" :id-pedido="pedido.id" @saved="fetchPago()" />
    </section>
</template>

<script>
import moment from 'moment';
import pagos from '~/services/pagos/pagos'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import PedidosAdmin from '~/services/pedidos/pedidos-admin'
export default {
    components:{
        modalDetallePago: () => import('../partials/modalDetallePago'),
        modalDetallePagoTransferencia: () => import('../partials/modalDetallePagoTransferencia'),
        modalRegistrarTransferencia: () => import('../partials/modalRegistrarTransferencia'),
        modalAnularCredito: () => import('~/pages/pedidos/partials/modalAnularCredito'),
        modalAnularCreditoTendero: () => import('~/pages/almacen/pedidos/partials/modalAnularCredito'),
    },
    props: {
        pedido: {
            type: Object,
            default: () => ({})
        },
        admin: {
            type: Boolean,
            default: false
        },
        vista: {
            type: Number,
            default: 0 // 1 = Abastecimiento Tendero, 2 = Pedidos Tendero, 3 = Pedidos Hitorial Tendero  4 = Pedidos Admin, 5 = Historial Pedidos Admin
        }
    },
    data(){
        return {
            valorPagar: 0,
            valorMaximo: 0,
            comentarioPago:'',

            formasPago: [
                { id: 1, tipo: 'Efectivo' },
                { id: 2, tipo: 'Transferencia' },
                { id: 3, tipo: 'Por comisión' },
            ],
            tipoPago: 1,

            id: 0,
            datos: {
                cuotas: [],
                historial: []
            },
            money:{
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            cajas: [],
            caja: '',
            loading:false
        }
    },
    computed:{
        botones(){
            if(this.vista === 2 || this.vista === 3){
                return this.pedido.directo === 0
            }
            return true
        }
    },
    watch: {
        pedido: {
            deep: true,
            handler(){
                this.fetchPago()
            }
        }
    },
    async mounted(){
        if(!_.isEmpty(this.pedido)){
            await this.fetchPago()
        } else {
            setTimeout(async() => {
                await this.fetchPago()
            }, 200);
        }
    },
    methods: {
        modalPronto(){
            this.$refs.modalPronto.toggle()
        },
        registrarTransferencia(){
            this.$refs.modalRegistrarTransferencia.toggle()
        },
        boxClass(datos){
            if(datos.pago_estado == 2) return 'box-pendiente'
            if(datos.pago_estado == 3) return 'box-aprobado'
            return 'box-anulado'
        },
        boxIcon(datos){
            if(datos.pago_estado == 2) return 'icon-cash-lock-open'
            if(datos.pago_estado == 3) return 'icon-cash'
            return 'icon-cash-remove'
        },
        textosCuota(estado){
            switch (estado){
            case 1:
                return 'Pagada';
            case 2:
                return 'Pago parcial';
            case 3:
                return 'Cuota vencida';
            case 4:
                return 'Próxima cuota';
            }
        },
        iconoCuotasEstablecidas(estado){
            switch (estado){
            case 1:
                return 'icon-ok-circled-outline text-success';
            case 2:
                return 'icon-alert-triangle text-warning';
            case 3:
                return 'icon-attention-alt text-general-red';
            case 4:
                return 'icon-attention-alt text-general';
            }
        },
        anularCredito(){
            if(this.vista === 3){
                this.$refs.modalAnularCreditoCliente.toggle()
            }
            if(this.vista === 4 || this.vista === 5){
                this.$refs.modalAnularCreditoTendero.toggle(this.pedido.id)
            }
        },
        detallePago(pago){
            if(pago.tipo_efectivo == 4) this.$refs.modalDetallePagoTransferencia.toggle(pago);
            else this.$refs.modalDetallePago.toggle(pago);
        },
        pagarPedido(){
            if(this.vista === 1){
                this.valorMaximo = _.cloneDeep(this.datos.debe)
                this.valorPagar = this.datos.debe
                this.money.precision = this.$tienda.decimales
                this.$refs.modalPagarPedidoTendero.toggle()
            }
            if(this.vista === 2 || this.vista === 3){
                this.valorMaximo = _.cloneDeep(this.datos.debe)
                this.valorPagar = this.datos.debe
                this.money.precision = this.$tienda.decimales
                this.comentarioPago = ''
                this.$refs.modalRegistrarPago.toggle()
            }
            if(this.vista === 4 || this.vista === 5){
                this.valorMaximo = _.cloneDeep(this.datos.debe)
                this.valorPagar = this.datos.debe
                this.money.precision = this.pedido.decimales_moneda
                this.comentarioPago = ''
                this.tipoPago = 1;
                this.cajas = []
                this.caja = ''
                this.buscarCaja()
                this.$refs.fotoTransferencia.instanciaCrop.remove()
                this.$refs.modalRegistrarPago.toggle()
            }
        },
        async fetchPago(){
            if(_.isEmpty(this.pedido)) return

            if(this.vista === 1 || this.vista === 4 || this.vista === 5){
                await this.pagoLeechero()

            }
            if(this.vista === 2 || this.vista === 3){
                await this.pagoCliente()

            }
        },
        async handlePago(){
            if(this.vista === 1){
                await this.registarPagoLeechero()
            }
            if(this.vista === 2 || this.vista === 3){
                await this.registarPagoCliente()
            }
            if(this.vista === 4 || this.vista === 5){
                this.pagoLeecheroManual()
            }

        },
        async pagoLeechero(){
            try {
                const params = {
                    id: this.pedido.id
                }
                const { data } = await pagos.pagoLeechero(params)
                this.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async pagoCliente(){
            try {
                const params = {
                    id: this.pedido.id
                }
                const { data } = await pagos.pagoCliente(params)
                this.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async buscarCaja(){
            try {
                if(!this.tipoPago || this.tipoPago == 3) return
                const params = {
                    pedido: this.pedido.id,
                    tipo: this.tipoPago
                }
                const { data } = await pagos.buscarCaja(params)
                this.cajas = data
                this.caja = ''

            } catch (error){
                this.error_catch(error)
            }
        },
        isParcial: (cuota) =>  cuota.valor_pendiente != 0 && (cuota.valor_pendiente != cuota.valor),
        iconoCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.iconoCuotasEstablecidas(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                const icono = moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD')) ? 3 : 4
                return this.iconoCuotasEstablecidas(icono)
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.iconoCuotasEstablecidas(2)
            }

        },
        textosCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.textosCuota(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                if (moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD'))){
                    return this.textosCuota(3)

                } else {
                    return this.textosCuota(4)

                }
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.textosCuota(2)
            }
        },
        async pagoLeecheroManual(){
            try {
                this.loading = true
                if(this.valorPagar == 0) return this.notificacion('Advertencia', 'El valor debe ser mayor a cero', 'warning')
                if(this.valorPagar > this.valorMaximo) return this.notificacion('Advertencia', `El valor máximo a pagar es de ${this.valorMaximo}`, 'warning')

                if(this.tipoPago != 3 && !this.caja) return this.notificacion('Error', 'Debe seleccionar una caja o una cuenta bancaria', 'error')

                const { image: foto } = this.$refs.fotoTransferencia.instanciaCrop.dataBase64.output

                const form = {
                    id_pedido_admin: this.pedido.id,
                    valor: this.valorPagar,
                    comentario: this.comentarioPago,
                    tipo_efectivo: this.tipoPago,
                    id_caja: this.caja,
                    foto
                }

                const { data } = await pagos.pagoLeecheroManual(form)
                this.notificacion('','Pago Registrado','success')
                this.fetchPago();
                this.valorPagar = 0
                this.$refs.modalRegistrarPago.toggle()
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async registarPagoLeechero(){
            try {
                this.loading = true
                if(this.valorPagar == 0) return this.notificacion('Advertencia', 'El valor debe ser mayor a cero', 'warning')
                if(this.valorPagar > this.valorMaximo) return this.notificacion('Advertencia', `El valor maximo a pagar es de ${this.valorMaximo}`, 'warning')

                const form = {
                    id_admin_pedido: this.pedido.id,
                    valor: this.valorPagar
                }
                const { data } = await pagos.initPagoLeechero(form)
                this.$refs.botonPayu.initBotonPayu(data.infoPago)

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        async registarPagoCliente(){
            try {
                this.loading = true
                if(this.valorPagar == 0) return this.notificacion('Error', 'El valor debe ser mayor a cero', 'error')
                if(this.valorPagar > this.valorMaximo) return this.notificacion('Advertencia', `El valor máximo a pagar es de ${this.valorMaximo}`, 'warning')

                const form = {
                    id_pedido: this.pedido.id,
                    valor: this.valorPagar,
                    comentario: this.comentarioPago
                }
                const { data } = await pagos.registarPagoCliente(form)
                await this.fetchPago();
                this.notificacion('', 'Se ha registrado el pago correctamente', 'success')
                this.valorPagar = 0
                this.$refs.modalRegistrarPago.toggle()
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        accionCancelar(res){
            if(!res){
                this.$refs.modalCancelarCredito.toggle()
                return
            }

            if(this.vista === 4){
                this.cancelarCreditoTendero()
            }
            if(this.vista === 2){
                this.cancelarCreditoCliente()
            }
        },
        async cancelarCreditoCliente(){ // De tendero a cliente en pedidos normales
            try {
                const {data} = await Pedidos.cancelar_credito(this.pedido.id)
                this.notificacion('Mensaje','Datos del credito borrados correctamente','success')
                this.pedido.pago_estado = 1
                this.$refs.modalCancelarCredito.toggle()
                this.$emit('cancelar')
            } catch (e){
                this.error_catch(e)
            }
        },
        async cancelarCreditoTendero(){ // De admin a tendero
            try {
                const {data} = await PedidosAdmin.cancelar_credito(this.pedido.id)
                this.notificacion('Mensaje','Datos del credito borrados correctamente','success')
                this.pedido.pago_estado = 1
                this.$refs.modalCancelarCredito.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        nombreHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return 'Transferencia';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return 'Transferencia';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return 'Por comisión'
            if(historial.estado == 0 && historial.tipo_efectivo == 4) return 'Registro de transferencia';
            return 'En línea'
        },
        imagenHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return '/img/icons/transferencia.svg';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return '/img/icons/transferencia.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 4) return '/img/icons/subir_imagen.svg';
            return '/img/icons/pago_online.svg';
        }
    }
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
.box-aprobado {
    background-color:#aeffb9;
    border:1px solid #27D07B;
}
.box-pendiente {
    background-color:#fff2b8;
    border:1px solid #ffe600;
}
.box-anulado {
    background-color:#F0F0F0;
    border:1px solid #707070;
}
</style>
